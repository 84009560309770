import React from 'react';

import bouwenImage1 from 'assets/images/pages/diensten_webshopbouwen/1.jpg';
import bouwenImage2 from 'assets/images/pages/diensten_webshopbouwen/2.jpg';
import bouwenImage3 from 'assets/images/pages/diensten_webshopbouwen/3.jpg';
import developmentImage from 'assets/images/pages/diensten_development/1.jpg';
import maatwerkImage from 'assets/images/pages/diensten_maatwerk/1.jpg';

import Title from 'blocks/Title';
import Grid from 'blocks/Grid';
import Image from 'blocks/Image';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import ImageBlock from 'components/ImageBlock';
import Text from 'components/Text';
import Button from 'components/Button';
import Quote from 'components/Quote';

const DienstenBouwen = () => (
  <Layout>
    <Seo image={{ src: bouwenImage1 }} />

    <Title
      image={{ src: bouwenImage1 }}
      alt="Jouw webshop bouwen met Batao"
      subtitle="Nieuwe of verbeterde webshop?"
      title="Webshop bouwen met Batao"
    >
      Wil je een nieuwe webshop bouwen of je bestaande webshop verbeteren? Dan
      ben je bij Batao aan het juiste adres! Wij hebben de kennis en ervaring om
      jou alle zorgen rondom het webshop bouwen uit handen te nemen.
    </Title>

    <Section>
      <Image image={{ src: bouwenImage2 }} alt="Webshop bouwen met Batao">
        <Heading size="xl" as="h2">
          Webshop bouwen met Batao: eerst een hartelijke ontmoeting
        </Heading>

        <Text>
          Intensief samenwerken vinden we belangrijk; het is de reden dat we
          erop staan dat we elkaar eerst goed leren kennen. De webshops die wij
          ontwikkelen zijn altijd op maat en houden rekening met de behoeftes
          van onze opdrachtgevers, zowel qua uitstraling als qua
          functionaliteit. Pas wanneer wij jouw organisatie door en door
          begrijpen zijn we in staat iets te bouwen dat perfect aansluit bij het
          merk.
        </Text>
      </Image>
    </Section>

    <Section>
      <Image
        image={{ src: bouwenImage3 }}
        alt="Wat we voor je kunnen betekenen"
        position="right"
      >
        <Heading size="xl" as="h2">
          Wat we voor je kunnen betekenen
        </Heading>

        <Text spacing="lg">
          Een webshop bouwen met Batao. Op deze pagina kun je precies zien wat
          we voor je kunnen betekenen. We ondersteunen je bij het ontwerp, de
          daadwerkelijke development en desgewenst bij je online marketing. Heb
          je vragen of wil je misschien gewoon kennis maken? Leuk! Neem contact
          met ons op of laat je gegevens achter zodat wij contact met jou kunnen
          opnemen!
        </Text>

        <Button color="tertiary" to="/contact">
          Laat je webshop door ons bouwen
        </Button>
      </Image>
    </Section>

    <Section>
      <Grid>
        <ImageBlock
          title="Development"
          image={{ src: developmentImage }}
          to="/diensten/webshop-bouwen/development"
        >
          <Text color="white" spacing="lg">
            Ons zeer ervaren en gekwalificeerde team staat voor je klaar om
            welke Magento opdracht dan ook aan te gaan.
          </Text>

          <Button as="span" color="white" hoverColor="secondary" outline>
            Lees verder
          </Button>
        </ImageBlock>

        <ImageBlock
          title="Maatwerk oplossingen"
          image={{ src: maatwerkImage }}
          to="/diensten/webshop-bouwen/maatwerk"
        >
          <Text color="white" spacing="lg">
            Geen standaard webwinkel maar een webshop die volledig is aangepast
            aan de behoeften van jouw organisatie?
          </Text>

          <Button as="span" color="white" hoverColor="secondary" outline>
            Lees verder
          </Button>
        </ImageBlock>

        <Quote author="Remco de Jong" position="Co Founder Batao" small>
          “Met Batao weet je zeker dat je toegang hebt tot een team van top
          IT-ers met een passie voor techniek, design en eCommerce.”
        </Quote>
      </Grid>
    </Section>

    <ContactCta />
  </Layout>
);

export default DienstenBouwen;
